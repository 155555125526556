import axios from "axios";
import {globalConstants} from "./globalVariables";
import {postRefreshToken} from "./billing";
import {LocalStorage} from "../app/helpers";

const BASEURL = globalConstants.BASE_URL;
const SITE_URL = globalConstants.SITE_URL;

axios.defaults.baseURL = BASEURL;

async function refreshTokenCallback() {
    let refreshToken = LocalStorage.getRefreshToken();

    if (sessionStorage.getItem('isReloading')) {
        return;
    }

    if (refreshToken) {
        try {
            const response = await postRefreshToken(refreshToken)
            if(response?.data?.accessToken){
                LocalStorage.setAccessToken(response.data.accessToken);
                return response.data.accessToken;
            }else{
                LocalStorage.setAccessToken();
                LocalStorage.setUser();

                sessionStorage.setItem('isReloading', 'true');

                window.location.reload();

                window.onbeforeunload = () => {
                    sessionStorage.removeItem('isReloading');
                }

            }
        } catch (error) {
            LocalStorage.setAccessToken();
            LocalStorage.setUser();

            sessionStorage.setItem('isReloading', 'true');

            window.location.reload();

            window.onbeforeunload = () => {
                sessionStorage.removeItem('isReloading');
            }

            console.error('Error refreshing token:', error.message);
        }
    }else{
        LocalStorage.setAccessToken();
        LocalStorage.setUser();

        sessionStorage.setItem('isReloading', 'true');

        window.location.reload();

        window.onbeforeunload = () => {
            sessionStorage.removeItem('isReloading');
        }

    }
}

// Axios interceptor to refresh token
axios.interceptors.request.use(
    async (config) => {
        const arraay = ['public/v1/user/otp/refresh', 'public/v1/user/otp/verify_login']
        const checkToken = arraay.some(c => config.url.search(c) !== -1);
        console.log(checkToken)
        const checkToken2 = config.url.search('public/v1/user/otp/verify_login')
        const token = LocalStorage.getAccessToken();
        if (token && !checkToken) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const loginUrls = ['public/v1/user/login', 'public/v1/user/otp/refresh'];

// Axios interceptor to retry failed requests after token refresh
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const isRefreshRequest = loginUrls.some(c => error.request.responseURL.includes(c));
        const originalRequest = error.config;
        // Check if the error is due to an expired token
        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshRequest) {
                LocalStorage.setAccessToken();
                LocalStorage.setUser();
                window.location.href = '/';
            } else {
                originalRequest._retry = true;
                try {
                    const newToken = await refreshTokenCallback();
                    originalRequest.headers.Authorization = `Bearer ${newToken}`;
                    return axios(originalRequest);

                } catch (refreshError) {
                    LocalStorage.setAccessToken();
                    LocalStorage.setUser();
                    window.location.href = '/';
                }
            }
        }

        return Promise.reject(error);
    }
);

export default axios;
